<template>
  <div id="app">
    <keep-alive>
      <!--        <NavBar></NavBar>-->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
  export default {
    name: "name",
    mounted() {
      let config = this.$store.state.config
      // if (config == ''){
      //   this.$router.push('/login')
      // }
    }
  }
</script>
<style lang="less">
@import url('~path_style/resize.css');
@import 'vant/lib/index.less';
#app {
  background-color: #131313;
  min-height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style>
  .van-toast{
    background: rgba(0,0,0,.7);
  }
  .error{
    color: red !important;
  }
  [v-cloak]{
    display: none !important;
  }

</style>
