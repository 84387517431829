import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible/index.js'
import axios from 'axios'
import { Icon, Divider, PullRefresh, Toast, List, Button, Empty, Loading, NavBar} from 'vant'
import common from '@/assets/js/common'
import https from './api/https'
import { Popup } from 'vant';

Vue.use(Popup);
// 导入资源文件
import {i18n, vantLocales} from './lang'



// vant-ui组件国际化
vantLocales(i18n.locale)

Vue.config.productionTip = false
Vue.use(Icon)
Vue.use(Divider)
Vue.use(PullRefresh)
Vue.use(Toast)
Vue.use(List)
Vue.use(Button)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(NavBar)

Vue.prototype.$axios = axios
Vue.prototype.$https = https
Vue.prototype.$common = common

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// router.beforeEach((to, from, next) => {
//     if (from.meta.keepAlive) {
//         const $content = document.querySelector('.list'); // 列表的外层容器
//         const scrollTop = $content ? $content.scrollTop : 0;
//         console.log('scrollTop', scrollTop)
//         from.meta.scrollTop = scrollTop;
//     }
//     next();
// });
router.beforeEach((to, form, next) => { //跳转前回调
  document.title = store.state.config.site_title + ' - ' + i18n.t(to.matched[0].meta.title)
  next()
})

